import FormSelectTable from '@/components/form/fields/formSelectTable';
import FormTextField from '@/components/form/fields/textField';
import PageSection from '@/components/page/section';
import { ItemsRead } from '@/data/management/item.graphql';
import currencyFormat from '@/helpers/currencyFormat';
import useUserInfo from '@/providers/auth/useUserInfo';
import { DeepPartial } from '@/types/deepPartial';
import { Category, Client, Item, Msrp, QueryItemsReadArgs } from '@/types/schema';
import { InputAdornment } from '@mui/material';
import { useFormikContext } from 'formik';
import { round } from 'lodash-es';
import { nanoid } from 'nanoid';

export default function Msrps() {
	const formik = useFormikContext<Client & { itemCategories: Category[] }>();
	const { staff } = useUserInfo();
	const cashDiscount = formik.values.company?.metadata?.hasCloverCashDiscount
		? round( staff?.company.metadata?.cashDiscount || 0, 2 ) / 100 : 0;
	
	return (
		<PageSection primary='Special Prices'>
			<FormSelectTable<Item, QueryItemsReadArgs, DeepPartial<Msrp>>
				disableCloseOnSelect
				name='msrps'
				textFieldProps={{ placeholder: 'Search By Item Name...' }}
				queryKey={[ 'items' ]}
				query={ItemsRead}
				blurOnSelect={false}
				variables={{
					options: {
						limit : 20,
						filter: {
							isHidden: null,
							uoms    : { removed: null },
							name    : { $ne: null },
						},
					},
				}}
				selectedOption={( item, msrps ) => Boolean( msrps?.find( ( msrp ) => msrp.item?.id === item.id ) )}
				getOptionLabel={( { name, description, uoms }: Item ) => [
					name,
					description,
					uoms?.length ? ` - ${uoms.filter( ( uom ) => !uom.removed )
						.map( ( uom ) => `(${currencyFormat( uom.price + uom.price * cashDiscount )}) ${uom.code
							? `(${uom.code})`
							: ''} ${uom.sku ? `(${uom.sku})` : ''}` )
						.join( ' ' )}`
						: '' ].join( ' ' ).trim()}
				tableProps={{
					hideAddIcon: true,
					headers    : [ 'Name', 'Price' ],
					columns    : ( msrp, index ) => [
						msrp.item?.name || '-',
						<FormTextField
							key='value'
							type='number'
							sx={{ minWidth: 100 }}
							name={`msrps.${index}.value`}
							InputProps={{
								startAdornment: <InputAdornment position='start'>$</InputAdornment>,
								inputProps    : { min: 0 },
							}}
							format={( value ) => Math.abs( +value ) || 0}
							onFocus={( event ) => event.target.select()}
						/>,
					],
				}}
				onChange={async ( event, value: Item ) => {
					if ( value ) {
						if ( formik.values.msrps?.find( ( msrp ) => msrp.item?.id === value.id ) ) {
							await formik.setFieldValue( 'msrps', formik.values.msrps?.filter( ( msrp ) => msrp.item?.id !== value.id ) );
						} else {
							await formik.setFieldValue( 'msrps', [ ...formik.values.msrps || [], {
								id   : nanoid(),
								value: value.uoms?.find( ( uom ) => uom.selected )?.price || value.uoms?.[ 0 ]?.price || 0,
								item : value,
								staff,
							} ] );
						}
					}
				}}
			/>
		</PageSection>
	);
}
