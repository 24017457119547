import CustomCheckBox from '@/components/customCheckBox';
import FormDatePicker from '@/components/form/fields/datePicker';
import FormTextField from '@/components/form/fields/textField';
import { ModalFormWrapper } from '@/components/form/modal';
import PageLinkComponent from '@/components/page/linkComponent';
import { mutateGraphQL } from '@/data/apollo';
import useUserInfo from '@/providers/auth/useUserInfo';
import { Client, ClientCredit, MutationClientCreditWriteArgs, Order } from '@/types/schema';
import { Box, Button, FormControlLabel, Stack, Typography } from '@mui/material';
import { set } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ClientCreditRead, ClientCreditWrite } from '../data/commerce/clientCredit.graphql';
import FormGraphqlProvider from '../data/query/formGraphqlProvider';
import MarkupPricesAndSavedFees from '../pages/dashboard/settings/doc/taxesAndFees/fees';

export default function NewClientCreditModal( {
	id,
	onSubmit,
	client,
	order,
	showCredits,
}: {
	id?: string,
	onSubmit?: ( clientCredit: ClientCredit ) => void,
	showCredits?: boolean,
	client: Client,
	order?: Order
} ) {
	const { staff } = useUserInfo();
	const { t } = useTranslation();
	const validationSchema = yup.object().shape( {
		amount: yup
			.number()
			.required( t( 'common:amount-required' ) )
			.min( 0 ),
	} );
	
	return (
		<FormGraphqlProvider<ClientCredit>
			id={id}
			queryKey='clientCredit'
			query={ClientCreditRead}
			initialValues={() => ( { prepaid: true } )}
			validationSchema={validationSchema}
			onSubmit={async ( values ) => {
				const { clientCreditWrite } = await mutateGraphQL<MutationClientCreditWriteArgs>( {
					mutation : ClientCreditWrite,
					variables: {
						id   : values?.id,
						input: {
							client    : client?.id,
							company   : order?.company?.id || client?.company?.id,
							staff     : staff?.id,
							amount    : values.amount ? +values.amount : 0,
							expiration: values.expiration || null,
							reason    : values.reason,
							selected  : values?.selected,
							prepaid   : values?.prepaid,
						},
					},
				} );
				set( values, 'id', clientCreditWrite?.id || values?.id || null );
				
				onSubmit?.( values );
			}}>
			{( formik ) =>
				(
					<ModalFormWrapper
						title={t( 'common:credits' )}
						secondaryTitle={`${t( 'common:create-credits-pre' )} ${client?.name || client?.contact || 'this client'} ${t( 'common:create-credits-post' )}`}
						saveButtonProps={{ disabled: !formik.values.amount }}>
						<Stack spacing={2}>
							<Stack
								direction='row'
								alignItems='center'
								justifyContent='space-between'
								px={.5}>
								<Typography>
									{t( 'common:expires-on' )}
								</Typography>
								<Box>
									<FormDatePicker name='expiration'/>
								</Box>
							</Stack>
							<Stack
								direction='row'
								alignItems='center'
								justifyContent='space-between'
								px={.5}>
								<Typography>
									{t( 'common:credit-amount' )}
								</Typography>
								<Box>
									<FormTextField
										name='amount'
										type='number'
										sx={{ width: 150 }}
										InputProps={{
											startAdornment: <Typography color='text.secondary' pr={.5}>$</Typography>,
											inputProps    : { min: 0 },
										}}
										onFocus={( event ) => event.target.select()}
									/>
								</Box>
							</Stack>
							<Stack
								direction='row'
								alignItems='center'
								justifyContent='space-between'
								px={.5}>
								<Typography>
									{`Usable by (${client.name || client.contact || client.email || 'Client'})`}
								</Typography>
								<Box>
									<FormControlLabel
										name='prepaid'
										label=''
										control={<CustomCheckBox/>}
										checked={formik.values.prepaid}
										onChange={( e, checked ) => formik.setFieldValue( 'prepaid', checked )}
									/>
								</Box>
							</Stack>
							<FormTextField
								fullWidth
								multiline
								rows={3}
								label={t( 'common:reason' )}
								name='reason'
								placeholder={t( 'common:reason-for-credit' )}
							/>
							{showCredits && (
								<Button
									variant='text'
									sx={{ alignSelf: 'start' }}
									component={PageLinkComponent}
									target='_blank'
									color='primary'
									href={`/dashboard/management/clients/${client.id}`}>
									View {client?.name || client?.contact}'s credits
								</Button>
							)}
							<MarkupPricesAndSavedFees name='trade' clientId={client.id}/>
						</Stack>
					</ModalFormWrapper>
				)}
		</FormGraphqlProvider>
	);
}
