import CustomCheckBox from '@/components/customCheckBox';
import { DollarPercentageSwitch } from '@/components/form/asyncLoading/switch/mui-switch';
import FormFormControlLabel from '@/components/form/fields/controlLabel';
import FormList from '@/components/form/fields/list';
import FormTextField from '@/components/form/fields/textField';
import { mutateGraphQL } from '@/data/apollo';
import { PriceWrite } from '@/graphql/price.graphql';
import { DeepPartial } from '@/types/deepPartial';
import { MutationPriceWriteArgs, Price } from '@/types/schema';
import { Chip, Divider, FormControlLabel, Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { validate } from 'uuid';

export default function FeeFormFields( { refetch, name } ) {
	const { t } = useTranslation();
	const formik = useFormikContext<any>();
	
	const defaultPrice = formik.values.prices?.find( ( price ) => price.metadata?.companyFee === true );
	
	const isSavedPrices = name === 'savedPrices';
	const isTrade = name === 'trade';
	
	const tableConfig = {
		trade: {
			tableHeaders: [
				t( 'common:name' ),
				t( 'settings:price' ),
			],
			newData: () => ( {
				id      : nanoid(),
				name    : 'Trade',
				value   : 0,
				metadata: null,
			} ),
		},
		savedPrices: {
			tableHeaders: [
				t( 'settings:auto-add' ),
				t( 'common:name' ),
				t( 'common:type' ),
				t( 'settings:price' ),
			],
			newData: () => ( {
				id       : nanoid(),
				name     : 'Fee',
				value    : 0,
				isPercent: false,
				metadata : null,
			} ),
		},
		markupPrices: {
			tableHeaders: [
				t( 'common:name' ),
				t( 'settings:price' ),
				t( 'common:type' ),
			],
			newData: () => ( {
				id       : nanoid(),
				name     : 'Fee',
				value    : 0,
				isPercent: false,
				metadata : null,
				isMarkup : true,
			} ),
		},
	};
	
	return (
		<FormList<DeepPartial<Price>>
			name='prices'
			pageSectionProps={{ hide: true }}
			extraData={formik.values}
			editable={{
				newData : tableConfig[ name ]?.newData,
				onDelete: async ( price ) => {
					if ( validate( price.id ) ) {
						await mutateGraphQL<MutationPriceWriteArgs>( {
							mutation : PriceWrite,
							variables: { id: price.id, remove: true },
						} );
					}
					await refetch();
				},
			}}
			listProps={{
				renderRow: ( item, index ) => (
					<Grid container>
						{isSavedPrices && (
							<Grid item xs={12}>
								<FormControlLabel
									label=''
									control={<CustomCheckBox/>}
									checked={defaultPrice?.id === item?.id}
									sx={{ px: 1 }}
									onChange={( e, checked ) => {
										const currentPrice = formik.values.prices[ index ];
										if ( checked ) {
											formik.setFieldValue( name, formik.values.prices.map( ( price ) => currentPrice.id === price.id
												? { ...price, metadata: { companyFee: true } }
												: { ...price, metadata: { companyFee: false } } ) );
										} else {
											formik.setFieldValue( name, formik.values.prices.map( ( price ) => ( {
												...price,
												metadata: { companyFee: false },
											} ) ) );
										}
									}}
								/>
							</Grid>
						)}
						<Grid item xs={12}>
							<FormTextField
								key='name'
								fullWidth
								name={`prices.${index}.name`}
							/>
						</Grid>
						{isSavedPrices ? (
							<Grid item xs={2}>
								<FormFormControlLabel
									name={`prices.${index}.isPercent`}
									label=''
									control={<DollarPercentageSwitch/>}
								/>
							</Grid>
						) : (
							<Grid item xs={10}>
								<FormTextField
									fullWidth
									name={`prices.${index}.value`}
									format={( value ) => +value || 0}
								/>
							</Grid>
						)}
						{isSavedPrices || isTrade ? (
							<Grid item xs={10}>
								<FormTextField
									fullWidth
									name={`prices.${index}.value`}
									format={( value ) => +value || 0}
								/>
							</Grid>
						) : (
							<Grid item xs={10}>
								<Chip
									color='default'
									variant='alpha'
									label='%'
								/>
							</Grid>
						)}
						{index !== formik.values.prices.length - 1 && (
							<Grid item xs={12} sx={{ my: 2 }}>
								<Divider/>
							</Grid>
						)}
					</Grid>
				),
			}}
			tableProps={{
				headers: tableConfig[ name ].tableHeaders,
				columns: ( item, index ) => [
					isSavedPrices && (
						<FormControlLabel
							key={index}
							label=''
							control={<CustomCheckBox/>}
							checked={defaultPrice?.id === item?.id}
							sx={{ px: 1 }}
							onChange={( e, checked ) => {
								const currentPrice = formik.values.prices[ index ];
								if ( checked ) {
									formik.setFieldValue( 'prices', formik.values.prices.map( ( price ) => currentPrice.id === price.id
										? { ...price, metadata: { companyFee: true } }
										: { ...price, metadata: { companyFee: false } } ) );
								} else {
									formik.setFieldValue( 'prices', formik.values.prices.map( ( price ) => ( {
										...price,
										metadata: { companyFee: false },
									} ) ) );
								}
							}}
						/>
					), <FormTextField
						key='name'
						fullWidth
						name={`prices.${index}.name`}
					/>, isTrade ? null : isSavedPrices ? (
						<FormFormControlLabel
							key='type'
							sx={{ textAlign: 'center' }}
							name={`prices.${index}.isPercent`}
							label=''
							control={<DollarPercentageSwitch/>}
						/>
					) : (
						<FormTextField
							key='Value'
							fullWidth
							includeNegative
							name={`prices.${index}.value`}
							type='number'
							format={( value ) => +value || 0}
							onFocus={( e ) => e.target.select()}
						/>
					
					), isTrade || isSavedPrices ? (
						<FormTextField
							key='Value'
							fullWidth
							includeNegative
							name={`prices.${index}.value`}
							type='number'
							format={( value ) => +value || 0}
							onFocus={( e ) => e.target.select()}
						/>
					) : (
						<Chip
							color='default'
							variant='alpha'
							label='%'
						/>
					),
				].filter( Boolean ),
			}}
		/>
	);
}
